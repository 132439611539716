import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/sitios`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(sitio) {
    return apiClient.post(`${sitio.id}`, sitio)
  },

  eliminar(idSitio) {
    return apiClient.delete(`${idSitio}`)
  },

  guardar(sitio) {
    return apiClient.post('', sitio)
  },

  sitioJSON(idSitio) {
    return apiClient.get(`${idSitio}.json`)
  },

  sitiosJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/sitios.json`, { params: params })
  },
  relsSitiosJSON(params) {
    return apiClient.get('rels-sitios.json', { params: params })
  }
}
