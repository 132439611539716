<template>
  <Layout :tituloPagina="'Network | Puntos de acceso | ' +(modo == 'nuevo' ? 'Nuevo' : 'Edición')">
    <div class="row">
      <div class="col-lg-7">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nuevo' ? 'Nuevo' : 'Edición de'}} punto de acceso
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Nombre del punto de acceso</label>
                <input
                  ref="nombre"
                  type="text"
                  class="form-control"
                  v-model="punto_acceso.nombre"
                  placeholder="Nombre del punto de acceso"
                />
              </div>

              <div class="col-md-6">
                <label>Estado</label>
                <select class="form-select" v-model="punto_acceso.estado">
                  <option>Funcional</option>
                  <option>Reparación</option>
                  <option>Descompuesto</option>
                </select>
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <label>Marca</label>
                <input
                  ref="marca"
                  type="text"
                  class="form-control"
                  v-model="punto_acceso.marca"
                  placeholder="Marca del punto de acceso"
                />
              </div>
              <div class="col-md-6">
                <label>Modelo</label>
                <input
                  ref="modelo"
                  type="text"
                  class="form-control"
                  v-model="punto_acceso.modelo"
                  placeholder="Modelo del punto de acceso"
                />
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <label>Dirección IP</label>
                <input
                  ref="direccion_ip"
                  type="text"
                  class="form-control"
                  v-model="punto_acceso.ip"
                  placeholder="IP del punto de acceso"
                />
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <label>Sitio</label>
                <select ref="id_sitio" v-model="punto_acceso.id_sitio" class="form-select"
                  title="Indica el sitio donde está instalado el punto de acceso">
                  <option :value="null">Seleccionar sitio</option>
                  <option
                    v-for="sitio in sitios"
                    :value="sitio.id"
                    :key="sitio.id"
                  >
                    {{ sitio.nombre }}
                  </option>
                </select>
                
              </div>
              <div class="col-md-6">
                <label>Router de administración</label>
                <select ref="id_sitio" v-model="punto_acceso.id_router" class="form-select"
                  title="Indica el router que le permite acceder a internet al Punto de Acceso (AP)"
                  @change="actualizarSitioSeleccionado()">
                  <option :value="null">Seleccionar router</option>
                  <option
                    v-for="router in routers"
                    :value="router.id"
                    :key="router.id"
                  >
                    {{ router.nombre }}
                  </option>
                </select>
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <label>MAC de la interfaz WLAN</label>
                <input
                  ref="mac_wan"
                  type="text"
                  class="form-control"
                  v-model="punto_acceso.mac_wan"
                  maxlength="17"
                  placeholder="MAC del puerto WAN en el punto de acceso"
                />
              </div>
              <div class="col-md-6">
                <label>MAC del puerto LAN</label>
                <input
                  ref="mac_lan"
                  type="text"
                  class="form-control"
                  maxlength="17"
                  v-model="punto_acceso.mac_lan"
                  placeholder="MAC del puerto LAN en el punto de acceso"
                />
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <label>SSID</label>
                <input
                  ref="ssid"
                  type="text"
                  class="form-control"
                  v-model="punto_acceso.ssid"
                  placeholder="SSID del punto de acceso"
                />
              </div>
              <div class="col-md-6">
                <label>Contraseña inalambrica</label>
                <div class="input-group">
                  <input
                    ref="clave_inalambrica"
                    :type="!mostrar_clave_inalambrica ? 'password' : 'text'"
                    class="form-control"
                    v-model="punto_acceso.clave_inalambrica"
                    placeholder="Contraseña inalambrica del punto de acceso"
                  />
                  <button
                    class="btn btn-light"
                    @click="
                      mostrar_clave_inalambrica = !mostrar_clave_inalambrica
                    "
                    :title="!mostrar_clave_inalambrica ? 'mostrar': 'ocultar'"
                  >
                    <i class="mdi font-size-15 text-muted" :class="mostrar_clave_inalambrica ? 'mdi-eye-off-outline': 'mdi-eye-outline'"></i>
                  </button>
                </div>
              </div>
            </div>

            <hr>
            <h3>Credencial de administración</h3>

            <div class="row">
              <div class="col-md-4">
                <label>Usuario</label>
                <input
                  ref="usuario"
                  type="text"
                  class="form-control"
                  v-model="punto_acceso.usuario"
                  placeholder="Usuario"
                />
              </div>
              <div class="col-md-4">
                <label>Clave</label>
                <div class="input-group">
                  <input
                    ref="clave"
                    :type="!mostrar_clave ? 'password' : 'text'"
                    class="form-control"
                    v-model="punto_acceso.clave"
                    placeholder="Clave del usuario"
                  />
                  <button
                    class="btn btn-light"
                    @click="
                      mostrar_clave = !mostrar_clave
                    "
                    :title="!mostrar_clave ? 'mostrar': 'ocultar'"
                  >
                    <i class="mdi font-size-15 text-muted" :class="mostrar_clave ? 'mdi-eye-off-outline': 'mdi-eye-outline'"></i>
                  </button>
                </div>
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-12">
                <label>Nota de información complementaria</label>
                <textarea
                  v-model="punto_acceso.nota"
                  class="form-control"
                  rows="5"
                  placeholder="Información complementaria"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-7 text-right">
        <div class="btn-group">
          <button class="btn btn-secondary" @click="atras()">
            <i class="mdi mdi-chevron-left"></i>
            Atrás
          </button>
          <button
            class="btn btn-success"
            @click="modo == 'nuevo' ? guardar() : actualizar()"
            :disabled="bandera_spinner"
          >
            <i 
              class="mdi"
              :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
            ></i>
            {{modo == 'nuevo' ? 'Guardar' : 'Actualizar'}}
          </button>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import PuntoAccesosSrv from '@/services/PuntoAccesosSrv.js'
import SitioSrv from '@/services/SitioSrv.js'
import RouterSrv from '@/services/RouterSrv.js'
import Swal from 'sweetalert2'
export default {
  name: 'EdicionAp',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      punto_acceso: {
        id: null,
        en_linea: 0,
        estado: 'Funcional', 
        marca: '',
        modelo: '',
        ip: '',
        latitud: null,
        longitud: null,
        n_clientes: 0,
        nombre: '',
        nota: '',
        ssid: '',
        ultimo_contacto: null,
        uptime: null,
        usuario: "",
        clave: '',
        clave_inalambrica: '',
        id_sitio: null,
        id_router: null,
      },
      sitios: [],
      routers: [],
      mostrar_clave: false,
      mostrar_clave_inalambrica: false,
      bandera_spinner: false
    };
  },

  created: function() {
    var self = this

    // Determinación del modo
    if (this.$route.path.indexOf('network/puntos-acceso/nuevo') != -1) this.modo = 'nuevo'
    else this.modo = 'edicion'

    self.cargarRouters()
    self.cargarSitios()

    if (this.modo == 'edicion') this.cargarPuntoAcceso()
  },

  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      let punto_acceso = Object.assign({}, self.punto_acceso)
      let sitio = null

      delete punto_acceso.en_linea
      delete punto_acceso.n_clientes
      delete punto_acceso.ultimo_contacto
      delete punto_acceso.uptime

      if (!self.datosCorrectos()) return


      if(punto_acceso.id_sitio != null) {
        sitio = self.obtenerSitio(punto_acceso.id_sitio)

        punto_acceso.latitud = sitio.latitud
        punto_acceso.longitud = sitio.longitud
      }

      PuntoAccesosSrv.actualizar(punto_acceso).then(response => {
        iu.msg.success('Se actualizó correctamente el punto de acceso')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el punto de acceso'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },


    actualizarSitioSeleccionado() {
      let self = this
      let router = self.routers.find(router => router.id == self.punto_acceso.id_router)

      if(router == undefined) return
      
      let sitioDeRouter = self.sitios.find(sitio => sitio.id == router.id_sitio)

      if(router != undefined) {
        const swal = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success me-1",
            cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
        });

        swal.fire({
          title: "Actualización de sitio",
          html: '¿Deseas actualizar el sitio del AP a <strong>'+sitioDeRouter.nombre+'</strong>?',
          icon: "question",
          confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
          cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            self.punto_acceso.id_sitio = router.id_sitio
            self.punto_acceso.latitud = router.latitud
            self.punto_acceso.longitud = router.longitud
            
            swal.fire("Actualizado!", "Se actualizo correctamente el sitio", "success")
          } else if ( result.dismiss === Swal.DismissReason.cancel ) {
            swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
          }
        })
      }
    },

    atras() {
      this.$router.go(-1)
    },

    cargarPuntoAcceso: function() {
      var self = this,
        idPuntoAcceso = this.$route.params.id

      PuntoAccesosSrv.puntoAccesoJSON(idPuntoAcceso).then(response => {
        self.punto_acceso = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el punto de acceso'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarSitios: function() {
      var self = this

      SitioSrv.sitiosJSON().then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    
    cargarRouters() {
      var self = this

      RouterSrv.routersJSON().then(response => {
        self.routers = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    

    cerrar: function() {
      this.atras()
    },

    datosCorrectos: function() {
      var self = this,
        punto_acceso = this.punto_acceso,
        regexMAC = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
        regexIP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      

      if (punto_acceso.nombre == '') {
        iu.msg.warning('Es necesario un nombre')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return false
      }

      if (punto_acceso.marca == '') {
        iu.msg.warning('Es necesario una marca')
        self.$refs.marca.focus()
        self.bandera_spinner = false
        return false
      }

      if (punto_acceso.modelo == '') {
        iu.msg.warning('Es necesario un modelo')
        self.$refs.modelo.focus()
        self.bandera_spinner = false
        return false
      }
      

      if(!regexIP.test(punto_acceso.ip)){
        iu.msg.warning("La dirección IP está mal escrita, verifique que ingreso correctamente la dirección IP")
        self.$refs.direccion_ip.focus()
        self.bandera_spinner = false
        return false
      }

      if(punto_acceso.ssid == '') {
        iu.msg.warning("Es necesario ingresar el SSID")
        self.$refs.ssid.focus()
        self.bandera_spinner = false
        return false
      }

      if(punto_acceso.mac_wan != '' && punto_acceso.mac_wan != null){
        // if(!regexMAC.test(punto_acceso.mac_wan)){
        //   iu.msg.warning('La dirección MAC del puerto WAN está mal escrita, verifique que ingreso correctamente la dirección MAC')
        //   self.$refs.mac_wan.focus()
        //   self.bandera_spinner = false
        //   return false
        // }
        if(punto_acceso.mac_wan.length > 17){
          iu.msg.warning('La dirección MAC del puerto WAN está mal escrita, verifique que ingreso correctamente la dirección MAC')
          self.$refs.mac_wan.focus()
          self.bandera_spinner = false
          return false
        }
      }

      if(punto_acceso.mac_lan != '' && punto_acceso.mac_lan != null){
        // if(!regexMAC.test(punto_acceso.mac_lan)){
        //   iu.msg.warning('La dirección MAC del puerto LAN está mal escrita, verifique que ingreso correctamente la dirección MAC')
        //   self.$refs.mac_lan.focus()
        //   self.bandera_spinner = false
        //   return false
        // }
        if(punto_acceso.mac_lan.length > 17){
          iu.msg.warning('La dirección MAC del puerto LAN está mal escrita, verifique que ingreso correctamente la dirección MAC')
          self.$refs.mac_lan.focus()
          self.bandera_spinner = false
          return false
        }
      }

      return true
    },

    guardar: function() {
      var self = this

      self.bandera_spinner = true

      let punto_acceso = Object.assign({}, self.punto_acceso)

      delete punto_acceso.en_linea
      delete punto_acceso.n_clientes
      delete punto_acceso.ultimo_contacto
      delete punto_acceso.uptime

      if (!self.datosCorrectos()) return

      let sitio = self.obtenerSitio(punto_acceso.id_sitio)

      punto_acceso.latitud = sitio.latitud
      punto_acceso.longitud = sitio.longitud

      PuntoAccesosSrv.guardar(punto_acceso).then(response => {
        iu.msg.success('Se guardó correctamente el punto de acceso')
        self.atras()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el punto de acceso'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    obtenerSitio(idSitio) {
      var self = this

      let sitio = self.sitios.find((sitio) => {
        return sitio.id == idSitio
      })
      
      return sitio
    }
  },
}
</script>

<style scoped>

</style>